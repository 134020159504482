import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import type { ElementState, TargetElement } from '#core/types'

type Item = {
  id: string
  taskTypeId: string
  position: number
}

export const useFieldAsDropTarget = (params: {
  target: TargetElement
  dragHandleTarget?: TargetElement
  item: Item | Ref<Item>
}) => {
  const { moveTaskTypeField } = useTaskTypeFieldMove()
  const cleanup = ref<CleanupFn>()
  const state = ref<ElementState>({
    type: 'idle',
  })
  const closestEdge = ref<Edge | null>(null)

  onMounted(() => {
    const { target, dragHandleTarget, item: pivot } = params
    const element = unrefElement(evaluativeFn(target))
    const dragHandle = unrefElement(evaluativeFn(dragHandleTarget))
    const reactivePivot = () => unref(pivot)
    const reactivePivotData = () => {
      const pivot = reactivePivot()
      return {
        type: 'task-type-field',
        id: pivot.id,
        position: pivot.position,
      }
    }

    if (reactivePivot()?.id) {
      cleanup.value = combine(
        draggable({
          element,
          dragHandle,
          getInitialData: reactivePivotData,
          onGenerateDragPreview: ({ location, source, nativeSetDragImage }) => {
            const rect = source.element.getBoundingClientRect()
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: () => {
                const offsetX = Math.min(
                  location.current.input.clientX - rect.x,
                  rect.width
                )
                const offsetY = Math.min(
                  location.current.input.clientY - rect.y,
                  rect.height
                )
                return { x: offsetX + 16, y: offsetY - rect.height / 2 + 16 }
              },
              render({ container }) {
                state.value = {
                  type: 'preview',
                  container,
                  rect,
                }
                return () => {
                  state.value = {
                    type: 'dragging',
                  }
                }
              },
            })
          },
          onDragStart: () => {
            state.value = {
              type: 'dragging',
            }
          },
          onDrop: () => {
            state.value = {
              type: 'idle',
            }
          },
        }),
        dropTargetForElements({
          element,
          canDrop: (args) => args.source.data.type === 'task-type-field',
          getIsSticky: () => true,
          getData: ({ input, element }) => {
            const data = reactivePivotData()
            return attachClosestEdge(data, {
              input,
              element,
              allowedEdges: ['top', 'bottom'],
            })
          },
          onDragEnter: (args) => {
            const item = args.source.data
            const pivot = reactivePivot()
            if (item?.id !== pivot.id) {
              closestEdge.value = extractClosestEdge(args.self.data)
            }
          },
          onDrag: (args) => {
            const item = args.source.data
            const pivot = reactivePivot()
            if (item?.id !== pivot.id) {
              closestEdge.value = extractClosestEdge(args.self.data)
            }
          },
          onDragLeave: () => {
            closestEdge.value = null
          },
          onDrop: ({ self, source }) => {
            closestEdge.value = null
            const pivot = reactivePivot()
            const currentClosestEdge = extractClosestEdge(self.data)
            const taskType = useReadTaskTypeFieldsFromCache(pivot.taskTypeId)!
            let indexOfTarget = taskType.fieldsWithPosition.findIndex(
              (field) => field.id === pivot.id
            )
            if (indexOfTarget === -1) {
              indexOfTarget = taskType.fieldsWithPosition.length
            }

            const startIndex = taskType.fieldsWithPosition.findIndex(
              (field) => field.id === (source.data.id as string)
            )
            const destinationIndex = getReorderDestinationIndex({
              startIndex,
              indexOfTarget,
              closestEdgeOfTarget: currentClosestEdge,
              axis: 'vertical',
            })
            moveTaskTypeField({
              index: destinationIndex,
              id: source.data.id as string,
              taskTypeId: pivot.taskTypeId,
            })
          },
        })
      )
    }
  })

  onBeforeUnmount(() => {
    cleanup.value?.()
  })

  return {
    state,
    closestEdge,
  }
}
